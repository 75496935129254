import React from "react";
import Layout from "../../components/layout";
import MarcaPage from "../../components/marcaPage";
import WarnerBanner from "../../images/desktop/marcas/warner/warnerbrosBanner.png";
import Batman from "../../images/desktop/marcas/warner/vatman.png";
import Superman from "../../images/desktop/marcas/warner/superman.png";
import Justice from "../../images/desktop/marcas/warner/justice.png";
import Wonder from "../../images/desktop/marcas/warner/wonderwoman.png";
import Looney from "../../images/desktop/marcas/warner/looney.png";
import Scooby from "../../images/desktop/marcas/warner/scooby.png";
import BareBears from "../../images/desktop/marcas/warner/barebears.png";
import Steven from "../../images/desktop/marcas/warner/steven.png";
import CowChiken from "../../images/desktop/marcas/warner/vacaypollito.png";
import Chowder from "../../images/desktop/marcas/warner/chowder.png";
import Fantastic from "../../images/desktop/marcas/warner/fantasticbeast.png";
import SexandCity from "../../images/desktop/marcas/warner/sexandthecity.png";
import Thunder from "../../images/desktop/marcas/warner/thundercats.png";
import It from "../../images/desktop/marcas/warner/it.png";
import Exorcist from "../../images/desktop/marcas/warner/theexorcist.png";
import Gilmore from "../../images/desktop/marcas/warner/gilmore.png";
import TeenTitans from "../../images/desktop/marcas/warner/teentitans.png";
import FlintStones from "../../images/desktop/marcas/warner/flinbtstones.png";
import TomJerry from "../../images/desktop/marcas/warner/tomjerry.png";
import AdventureTime from "../../images/desktop/marcas/warner/adventuretime.png";
import Dexter from "../../images/desktop/marcas/warner/dexter.png";
import RegularShow from "../../images/desktop/marcas/warner/regularshow.png";
import Edd from "../../images/desktop/marcas/warner/edeededdy.png";
import Got from "../../images/desktop/marcas/warner/got.png";
import Friends from "../../images/desktop/marcas/warner/friends.png";
import Lord from "../../images/desktop/marcas/warner/lord.png";
import Gremlins from "../../images/desktop/marcas/warner/gremlins.png";
import Friday from "../../images/desktop/marcas/warner/friday.png";
import Riverdale from "../../images/desktop/marcas/warner/riverdale.png";
import Nightmare from "../../images/desktop/marcas/warner/Anightmare.png";
import Gossip from "../../images/desktop/marcas/warner/gossip.png";
import Bigbang from "../../images/desktop/marcas/warner/thebigbangtheory.png";
import SuperFriends from "../../images/desktop/marcas/warner/superfriends.png";
import Jetsons from "../../images/desktop/marcas/warner/jetsons.png";
import RickMorty from "../../images/desktop/marcas/warner/rickandmorty.png";
import Ben10 from "../../images/desktop/marcas/warner/ben10.png";
import JhonyBravo from "../../images/desktop/marcas/warner/johnybravo.png";
import Weasel from "../../images/desktop/marcas/warner/iamweasel.png";
import Gumbnail from "../../images/desktop/marcas/warner/gumball.png";
import House from "../../images/desktop/marcas/warner/houseofthedragon.png";
import Beetlejuice from "../../images/desktop/marcas/warner/beetlejuice.png";
import MortalKombat from "../../images/desktop/marcas/warner/mortalkombat.png";
import Annabelle from "../../images/desktop/marcas/warner/annabelle.png";
import SuperHeroG from "../../images/desktop/marcas/warner/superherogirls.png";
import Wacky from "../../images/desktop/marcas/warner/wacky.png";
import Cartoon from "../../images/desktop/marcas/warner/cartoonnetwokr.png";
import Powerpuff from "../../images/desktop/marcas/warner/powerpuff.png";
import Courage from "../../images/desktop/marcas/warner/courage.png";
import Billy from "../../images/desktop/marcas/warner/billyandmandy.png";
import WillyWonka from "../../images/desktop/marcas/warner/wonka.png";
import HarryPotter from "../../images/desktop/marcas/warner/harrypotter.png";
import Matrix from "../../images/desktop/marcas/warner/matrix.png";
import Horror from "../../images/desktop/marcas/warner/horror.png";
import Llorona from "../../images/desktop/marcas/warner/lallorona.png";
import Dc from "../../images/desktop/marcas/warner/dc.png";

const WarnerPage = () => {

  return (
    <Layout>
      <MarcaPage backgroundImage={WarnerBanner}>
        <img alt="marca" src={Dc} />
        <img alt="marca" src={Justice} />
        <img alt="marca" src={Batman} />
        <img alt="marca" src={Superman} />
        <img alt="marca" src={Wonder} />
        <img alt="marca" src={TeenTitans} />
        <img alt="marca" src={SuperFriends} />
        <img alt="marca" src={SuperHeroG} />
        <img alt="marca" src={Looney} />
        <img alt="marca" src={FlintStones} />
        <img alt="marca" src={Jetsons} />
        <img alt="marca" src={Wacky} />
        <img alt="marca" src={Scooby} />
        <img alt="marca" src={TomJerry} />
        <img alt="marca" src={RickMorty} />
        <img alt="marca" src={Cartoon} />
        <img alt="marca" src={BareBears} />
        <img alt="marca" src={AdventureTime} />
        <img alt="marca" src={Ben10} />
        <img alt="marca" src={Powerpuff} />
        <img alt="marca" src={Steven} />
        <img alt="marca" src={Dexter} />
        <img alt="marca" src={JhonyBravo} />
        <img alt="marca" src={Courage} />
        <img alt="marca" src={CowChiken} />
        <img alt="marca" src={RegularShow} />
        <img alt="marca" src={Weasel} />
        <img alt="marca" src={Billy} />
        <img alt="marca" src={Chowder} />
        <img alt="marca" src={Edd} />
        <img alt="marca" src={Gumbnail} />
        <img alt="marca" src={WillyWonka} />
        <img alt="marca" src={Fantastic} />
        <img alt="marca" src={Got} />
        <img alt="marca" src={House} />
        <img alt="marca" src={HarryPotter} />
        <img alt="marca" src={SexandCity} />
        <img alt="marca" src={Friends} />
        <img alt="marca" src={Beetlejuice} />
        <img alt="marca" src={Matrix} />
        <img alt="marca" src={Thunder} />
        <img alt="marca" src={Lord} />
        <img alt="marca" src={MortalKombat} />
        <img alt="marca" src={Horror} />
        <img alt="marca" src={It} />
        <img alt="marca" src={Gremlins} />
        <img alt="marca" src={Annabelle} />
        <img alt="marca" src={Llorona} />
        <img alt="marca" src={Exorcist} />
        <img alt="marca" src={Friday} />
        <img alt="marca" src={Nightmare} />
        <img alt="marca" src={Bigbang} />
        <img alt="marca" src={Gilmore} />
        <img alt="marca" src={Riverdale} />
        <img alt="marca" src={Gossip} />
      </MarcaPage>
    </Layout>
  );
};

export default WarnerPage;
